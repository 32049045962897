import React, { Component } from 'react'
import Main from '../../layouts/Main'
import BackHomeSecond from '../../BuildDoor/BackHomeSecond'
import MainBoxAsite from '../../GeneralComponents/MainBoxAsite'
import MainBoxConstructor from '../../layouts/MainBoxConstructor'
import ConsumerFlowContainer from './ConsumerFlowContainer'
import { connect } from 'react-redux'
import { getPartsOfBrand } from './../../../redux/actions/parts'

import { withRouter } from 'react-router-dom'
import NavConstructor from '../../ElementsConstructor/NavConstructor'

class ConsumerFlowConstructor extends Component {
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.model !== prevProps.model ||
      this.props.modelColors !== prevProps.modelColors ||
      this.props.order !== prevProps.order ||
      this.props.side !== prevProps.side
    ) {
      this.props.renderCanvas()
    }
  }

  nextDoorStyle = () => {
    this.props.setPage('frame_style')
  }

  nextFrameStyle = () => {
    this.props.setPage('outside-door-color')
  }

  nextOutsideDoorColor = () => {
    this.props.setPage('outside-frame-color')
  }
  nextOutsideFrameColor = () => {
    this.props.setPage('inside-door-color')
  }
  nextInsideDoorColor = () => {
    this.props.setPage('inside-frame-color')
  }

  nextInsideFrameColor = () => {
    this.props.setPage('glass-design')
  }

  nextGlassDesign = () => {
    this.props.setPage('furniture-type-handle')
  }

  // nextFurnitureGroup = () => {
  //   this.props.setPage("furniture-type-handle");
  // };
  nextFurnitureTypeHandle = () => {
    let validate = Object.keys(this.props.activeHandleBrand).length > 0
    if (validate) {
      this.props.setPage('furniture-color-handle')
    } else {
      this.props.setIsHandleBrandError(true)
    }
  }

  nextFurnitureColorHandle = () => {
    let handle = this.props.handle
    if (!!handle) {
      this.props.setPage('furniture-type-knocker')
    } else {
      this.props.setIsHandleError(true)
    }
  }

  nextFurnitureTypeKnocker = () => {
    this.props.setPage('furniture-type-letterplate')
  }
  nextFurnitureTypeLetterplate = () => {
    this.props.setPage('furniture-side-hinges')
  }

  nextFurnitureSideHinges = () => {
    this.props.setPage('my-dream-door')
  }

  nextMyDreamDoor = () => {
    this.props.setPage('thank-you')
  }

  filterParts = (page) => {
    let parts = this.props.parts.filter((part) => {
      return part.category_part_slug === page
    })
    return parts
  }

  render() {
    return (
      <>
        {' '}
        <Main
          header={!this.props.match.params.iframePartnerPath}
          classAdd="constructor"
        >
          <MainBoxAsite classAdd="constructor">
            <BackHomeSecond title="HOME PAGE" />

            <NavConstructor
              page={this.props.page}
              navigateConstructorNav={this.props.navigateConstructorNav}
              navLinks={this.props.navLinks}
              navLinkStep={this.props.navLinkStep}
              model={this.props.model}
            />
          </MainBoxAsite>

          <MainBoxConstructor>
            <ConsumerFlowContainer
              affiliate={this.props.affiliate}
              brand={this.props.brand}
              brandTitle={this.props.brandTitle}
              setBrand={this.props.setBrand}
              page={this.props.page}
              setPage={this.props.setPage}
              image={this.props.image}
              parts={this.props.parts}
              model={this.props.model}
              handle={this.props.handle}
              chooseComponent={this.props.chooseComponent}
              checkLayerInModel={this.props.checkLayerInModel}
              findColorsPart={this.props.findColorsPart}
              filterParts={this.filterParts}
              chooseColorPart={this.props.chooseColorPart}
              checkLayerInModelColor={this.props.checkLayerInModelColor}
              findInternalColorsPart={this.props.findInternalColorsPart}
              setOrder={this.props.setOrder}
              loadHandelBrands={this.props.loadHandelBrands}
              order={this.props.order}
              handelBrands={this.props.handelBrands}
              activeHandleBrand={this.props.activeHandleBrand}
              chooseHandelBrand={this.props.chooseHandelBrand}
              handles={this.props.handles}
              knockers={this.props.knockers}
              loadKnockers={this.props.loadKnockers}
              letterplates={this.props.letterplates}
              loadLetterplates={this.props.loadLetterplates}
              side={this.props.side}
              changeSide={this.props.changeSide}
              chooseGlassDouble={this.props.chooseGlassDouble}
              loadGlassByModel={this.props.loadGlassByModel}
              glasses={this.props.glasses}
              glassActive={this.props.glassActive}
              backButtonHandler={this.props.backButtonHandler}
              nameOnChangeHandler={this.props.nameOnChangeHandler}
              navigateConstructorNav={this.props.navigateConstructorNav}
              addressNumber1OnChangeHandler={
                this.props.addressNumber1OnChangeHandler
              }
              addressNumber2OnChangeHandler={
                this.props.addressNumber2OnChangeHandler
              }
              countyOnChangeHandler={this.props.countyOnChangeHandler}
              postcodeOnChangeHandler={this.props.postcodeOnChangeHandler}
              mobileOnChangeHandler={this.props.mobileOnChangeHandler}
              emailOnChangeHandler={this.props.emailOnChangeHandler}
              couponOnChangeHandler={this.props.couponOnChangeHandler}
              formData={this.props.formData}
              saveOrder={this.props.saveOrder}
              loadFrameGlasses={this.props.loadFrameGlasses}
              frameGlasses={this.props.frameGlasses}
              chooseFrameGlass={this.props.chooseFrameGlass}
              findLayer={this.props.findLayer}
              setSeeMyDoor={this.props.setSeeMyDoor}
              scale={this.props.scale}
              setScale={this.props.setScale}
              backgroundColor={this.props.backgroundColor}
              setBackgroundColor={this.props.setBackgroundColor}
              setShowImageMobile={this.props.setShowImageMobile}
              loadFurnitureGroup={this.props.loadFurnitureGroup}
              furnitureGroups={this.props.furnitureGroups}
              chooseFurnitureGroup={this.props.chooseFurnitureGroup}
              browseAllFurniture={this.props.browseAllFurniture}
              showImageMobile={this.props.showImageMobile}
              setLoader={this.props.setLoader}
              isHandleBrandError={this.props.isHandleBrandError}
              isHandleError={this.props.isHandleError}
              setIsHandleBrandError={this.props.setIsHandleBrandError}
              setIsHandleError={this.props.setIsHandleError}
              outsideDoorColours={this.props.outsideDoorColours}
              insideDoorColours={this.props.insideDoorColours}
              outsideFrameColours={this.props.outsideFrameColours}
              insideFrameColours={this.props.insideFrameColours}
              loadOutsideDoorColors={this.props.loadOutsideDoorColors}
              loadInsideDoorColors={this.props.loadInsideDoorColors}
              loadOutsideFrameColors={this.props.loadOutsideFrameColors}
              loadInsideFrameColors={this.props.loadInsideFrameColors}
              loadLeftOrRight={this.props.loadLeftOrRight}
              nextDoorStyle={() => {
                this.nextDoorStyle()
              }}
              nextFrameStyle={() => {
                this.nextFrameStyle()
              }}
              nextOutsideDoorColor={() => {
                this.nextOutsideDoorColor()
              }}
              nextInsideDoorColor={() => {
                this.nextInsideDoorColor()
              }}
              nextOutsideFrameColor={() => {
                this.nextOutsideFrameColor()
              }}
              nextInsideFrameColor={() => {
                this.nextInsideFrameColor()
              }}
              nextGlassDesign={() => {
                this.nextGlassDesign()
              }}
              nextGlassFrame={() => {
                this.nextGlassFrame()
              }}
              nextFurnitureTypeHandle={() => {
                this.nextFurnitureTypeHandle()
              }}
              nextFurnitureColorHandle={() => {
                this.nextFurnitureColorHandle()
              }}
              nextFurnitureTypeKnocker={() => {
                this.nextFurnitureTypeKnocker()
              }}
              nextFurnitureTypeLetterplate={() => {
                this.nextFurnitureTypeLetterplate()
              }}
              nextFurnitureSideHinges={() => {
                this.nextFurnitureSideHinges()
              }}
              nextMyDreamDoor={() => {
                this.nextMyDreamDoor()
              }}
              nextFurnitureGroup={() => {
                this.nextFurnitureGroup()
              }}
            />
          </MainBoxConstructor>
          <canvas
            style={{ display: 'none' }}
            id={'canvas-builder'}
            ref={this.canvasRef}
          />
        </Main>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {}
}
function mapDispatchToProps(dispatch) {
  return {
    getPartsOfBrand: (brand) => {
      return dispatch(getPartsOfBrand(brand))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConsumerFlowConstructor))
